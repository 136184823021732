import React, { useContext, useMemo, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import { IDriver } from '../../interfaces/Registration'

interface IProps {
  datepickerUsedFor: 'Adult Form' | 'Adult Junior Form' | 'Junior Form'
  juniorItem?: {
    index: number
    driver: IDriver
  }
}

const CustomDatepicker = ({ datepickerUsedFor, juniorItem }: IProps) => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, selectedDOB, driver, selectedDOBError, drivers, juniors } =
    registrationContext
  const [errorDispatched, setErrorDispatched] = useState(false)

  const months = useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    []
  )

  const days = useMemo(() => Array.from({ length: 31 }, (_, i) => i + 1), [])
  const currentYear = new Date().getFullYear()

  const years = useMemo(
    () =>
      Array.from({ length: currentYear - 1924 + 1 }, (_, i) => currentYear - i),
    [currentYear]
  )

  const handleDateChange = (field: 'day' | 'month' | 'year', value: string) => {
    if (
      datepickerUsedFor === 'Adult Form' ||
      datepickerUsedFor === 'Adult Junior Form'
    ) {
      dispatch({
        type: 'SET_SELECTED_DOB',
        payload: { selectedDOB: { ...selectedDOB, [field]: value } },
      })
    } else if (datepickerUsedFor === 'Junior Form' && juniorItem) {
      let xJuniors = [...drivers, ...juniors]

      const reformatDate: { day: string; month: string; year: string } = {
        day:
          field === 'day'
            ? value
            : juniorItem.driver.juniorSelectedDOB
            ? juniorItem.driver.juniorSelectedDOB.day
            : '',
        month:
          field === 'month'
            ? value
            : juniorItem.driver.juniorSelectedDOB
            ? juniorItem.driver.juniorSelectedDOB.month
            : '',
        year:
          field === 'year'
            ? value
            : juniorItem.driver.juniorSelectedDOB
            ? juniorItem.driver.juniorSelectedDOB.year
            : '',
      }

      const { day, month, year } = reformatDate

      if (!day || !month || !year) {
        const junior: IDriver = {
          ...xJuniors[juniorItem.index],
          juniorSelectedDOB: reformatDate,
          juniorDOBErrorMesssage: 'All date fields must be selected',
        }

        xJuniors[juniorItem.index] = junior

        dispatch({
          type: 'SET_DRIVERS',
          payload: {
            drivers: xJuniors,
          },
        })
      } else {
        const junior: IDriver = {
          ...xJuniors[juniorItem.index],
          juniorSelectedDOB: reformatDate,
        }

        xJuniors[juniorItem.index] = junior

        dispatch({
          type: 'SET_DRIVERS',
          payload: {
            drivers: xJuniors,
          },
        })
      }
    }
    setErrorDispatched(false)
  }

  useEffect(() => {
    if (driver && selectedDOB) {
      const { day, month, year } = selectedDOB
      if (day && month && year) {
        const reformatDate = `${day.padStart(2, '0')}-${(
          months.indexOf(month) + 1
        )
          .toString()
          .padStart(2, '0')}-${year}`

        if (datepickerUsedFor === 'Adult Form') {
          const isAbove16 =
            DateTime.fromFormat(reformatDate, 'dd-MM-yyyy').toMillis() <
            DateTime.now().minus({ years: 16 }).toMillis()
          if (isAbove16) {
            if (driver.dob !== reformatDate) {
              dispatch({
                type: 'SET_REGISTRATION_DRIVER',
                payload: { driver: { ...driver, dob: reformatDate } },
              })

              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError: '',
                },
              })

              setErrorDispatched(false)
            }
          } else {
            if (!errorDispatched) {
              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError:
                    'Drivers under 16 must have a parent or guardian fill out this form',
                },
              })
              setErrorDispatched(true)
            }
          }
        }
        if (datepickerUsedFor === 'Adult Junior Form') {
          const isAbove18 =
            DateTime.fromFormat(reformatDate, 'dd-MM-yyyy').toMillis() <
            DateTime.now().minus({ years: 18 }).toMillis()

          if (isAbove18) {
            if (driver.guardian_dob !== reformatDate) {
              dispatch({
                type: 'SET_REGISTRATION_DRIVER',
                payload: { driver: { ...driver, guardian_dob: reformatDate } },
              })

              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError: '',
                },
              })

              setErrorDispatched(false)
            }
          } else {
            if (!errorDispatched) {
              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError: 'Guardian age must be over 18',
                },
              })
              setErrorDispatched(true)
            }
          }
        }
      }
    }
    if (datepickerUsedFor === 'Junior Form' && juniorItem) {
      if (juniorItem.driver?.juniorSelectedDOB) {
        const { day, month, year } = juniorItem.driver?.juniorSelectedDOB

        if (day && month && year) {
          const reformatDate = `${day.padStart(2, '0')}-${(
            months.indexOf(month) + 1
          )
            .toString()
            .padStart(2, '0')}-${year}`

          const isUnder16 =
            DateTime.fromFormat(reformatDate, 'dd-MM-yyyy').toMillis() >
            DateTime.now().minus({ years: 16 }).toMillis()

          const isAbove16 =
            DateTime.fromFormat(reformatDate, 'dd-MM-yyyy').toMillis() <
            DateTime.now().minus({ years: 16 }).toMillis()

          let xJuniors = [...drivers, ...juniors]

          if (isUnder16 && xJuniors[juniorItem.index].dob !== reformatDate) {
            const junior = {
              ...xJuniors[juniorItem.index],
              dob: reformatDate,
              juniorDOBErrorMesssage: '',
            }

            xJuniors[juniorItem.index] = junior

            dispatch({
              type: 'SET_DRIVERS',
              payload: {
                drivers: xJuniors,
              },
            })
          }

          if (
            isAbove16 &&
            juniorItem.driver &&
            !juniorItem.driver.juniorDOBErrorMesssage
          ) {
            const junior = {
              ...xJuniors[juniorItem.index],
              juniorDOBErrorMesssage: 'Drivers age must be under 16',
            }

            xJuniors[juniorItem.index] = junior

            dispatch({
              type: 'SET_DRIVERS',
              payload: {
                drivers: xJuniors,
              },
            })
          }
        }
      }
      if (
        juniorItem.driver &&
        juniorItem.driver.dob &&
        !juniorItem.driver?.juniorSelectedDOB
      ) {
        const { dob } = juniorItem.driver

        const destructureDob = DateTime.fromFormat(dob, 'dd-MM-yyyy')
        const day = destructureDob.day.toString()
        const month = months[destructureDob.month - 1].toString()
        const year = destructureDob.year.toString()

        let xJuniors = [...drivers, ...juniors]

        const junior = {
          ...xJuniors[juniorItem.index],
          juniorSelectedDOB: { day, month, year },
        }

        xJuniors[juniorItem.index] = junior

        dispatch({
          type: 'SET_DRIVERS',
          payload: {
            drivers: xJuniors,
          },
        })
      }
    }
  }, [
    datepickerUsedFor,
    dispatch,
    driver,
    selectedDOB,
    months,
    errorDispatched,
    juniorItem,
    drivers,
    juniors,
  ])

  const handleBlur = () => {
    if (
      datepickerUsedFor === 'Adult Form' ||
      datepickerUsedFor === 'Adult Junior Form'
    ) {
      if (!selectedDOB.day || !selectedDOB.month || !selectedDOB.year) {
        dispatch({
          type: 'SET_SELECTED_DOB_ERROR',
          payload: {
            selectedDOBError: 'All date fields must be selected',
          },
        })
      }
    }

    if (
      datepickerUsedFor === 'Junior Form' &&
      juniorItem &&
      juniorItem.driver
    ) {
      const { juniorSelectedDOB } = juniorItem.driver
      if (juniorSelectedDOB) {
        if (
          !juniorSelectedDOB.day ||
          !juniorSelectedDOB.month ||
          !juniorSelectedDOB.year
        ) {
          dispatch({
            type: 'SET_SELECTED_DOB_ERROR',
            payload: {
              selectedDOBError: 'All date fields must be selected',
            },
          })
        }
      }
    }
  }

  const getSelectedDOB: { day: string; month: string; year: string } =
    useMemo(() => {
      if (
        datepickerUsedFor === 'Junior Form' &&
        juniorItem &&
        juniorItem?.driver
      ) {
        if (juniorItem.driver.juniorSelectedDOB) {
          return juniorItem.driver.juniorSelectedDOB
        }
      }

      if (
        datepickerUsedFor === 'Adult Form' ||
        datepickerUsedFor === 'Adult Junior Form'
      ) {
        const { day, month, year } = selectedDOB
        return { day, month, year }
      }

      return { day: '', month: '', year: '' }
    }, [datepickerUsedFor, juniorItem, selectedDOB])

  return (
    <>
      <div className="flex flex-col min-[380px]:flex-row gap-[5px]">
        <FormControl variant="outlined" style={{ minWidth: 80 }}>
          <InputLabel
            id="day-label"
            sx={{
              '&.Mui-focused': {
                color:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
            }}
          >
            Day
          </InputLabel>
          <Select
            labelId="day-label"
            value={getSelectedDOB.day}
            onChange={(event: SelectChangeEvent<string>) =>
              handleDateChange('day', event.target.value)
            }
            onBlur={handleBlur}
            label="Day"
            sx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : 'inherit',
                borderRadius: '12px',
              },
            }}
          >
            {days.map(d => (
              <MenuItem key={d} value={d.toString()}>
                {d}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel
            id="month-label"
            sx={{
              '&.Mui-focused': {
                color:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
            }}
          >
            Month
          </InputLabel>
          <Select
            labelId="month-label"
            value={getSelectedDOB.month}
            onChange={(event: SelectChangeEvent<string>) =>
              handleDateChange('month', event.target.value)
            }
            onBlur={handleBlur}
            label="Month"
            sx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : 'inherit',
                borderRadius: '12px',
              },
            }}
          >
            {months.map((m, index) => (
              <MenuItem key={index} value={m}>
                {m}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 90 }}>
          <InputLabel
            id="year-label"
            sx={{
              '&.Mui-focused': {
                color:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
            }}
          >
            Year
          </InputLabel>
          <Select
            labelId="year-label"
            value={getSelectedDOB.year}
            onChange={(event: SelectChangeEvent<string>) =>
              handleDateChange('year', event.target.value)
            }
            onBlur={handleBlur}
            label="Year"
            sx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : '#9d62fe',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor:
                  datepickerUsedFor === 'Junior Form' &&
                  juniorItem?.driver.juniorDOBErrorMesssage
                    ? 'red'
                    : (datepickerUsedFor === 'Adult Junior Form' ||
                        datepickerUsedFor === 'Adult Form') &&
                      selectedDOBError
                    ? 'red'
                    : 'inherit',
                borderRadius: '12px',
              },
            }}
          >
            {years.map(y => (
              <MenuItem key={y} value={y.toString()}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {selectedDOBError && datepickerUsedFor === 'Adult Form' && (
        <p className="text-red-500 text-sm">{selectedDOBError}</p>
      )}
      {datepickerUsedFor === 'Adult Junior Form' && (
        <p
          className={`text-red-500 text-sm ${
            selectedDOBError ? 'visible' : 'invisible'
          }`}
        >
          {selectedDOBError}
        </p>
      )}
    </>
  )
}

export default CustomDatepicker
