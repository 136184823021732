import React, {
  useState,
  useContext,
  useEffect,
  MouseEventHandler,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { LinkIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'
import {
  BOOKING_COPY_LINK,
  BOOKING_DRIVERS_NAME_HEADER,
  BOOKING_DRIVERS_NO_HEADER,
  BOOKING_DRIVERS_STATUS_HEADER,
  BOOKING_INSTRUCTION_1,
  BOOKING_INSTRUCTION_2,
  BOOKING_NO_NAME,
  BOOKING_REGISTERED_DRIVERS,
  BOOKING_REGISTER_BUTTON_LABEL,
  SCREEN_NAMES,
} from './Constants'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import { useToast } from '../../context/ToastContext'
import { IOrderLineItem } from 'shopify-api-node'
import BookingRaceItems from './BookingRaceItems'
import BookingDriversList from './BookingDriversList'
import { skuToSessionDate } from '../../utils/RegistrationHelpers'

type Props = {}

const BookingDetails = (props: Props) => {
  const [bookingDate, setBookingDate] = useState('')
  const [lineItems, setLineItems] = useState([] as IOrderLineItem[])
  const registrationContext = useContext(RegistrationContext)
  let navigate = useNavigate()
  const { order, orderId, booking, dispatch, metaobjectConfig } =
    registrationContext as IRegistrationStateValue

  const toast = useToast()

  useEffect(() => {
    const getOrderData = () => {
      const lineItems = order?.line_items || []
      const filterLineItems = lineItems.filter(
        item =>
          lineItems?.length > 0 &&
          !item?.title?.toLowerCase()?.includes('gift card')
      )
      setLineItems(filterLineItems)
      const order_date = order?.created_at || ''
      setBookingDate(DateTime.fromJSDate(new Date(order_date)).toLocaleString())
    }
    getOrderData()
  }, [order])

  const racesBlock = lineItems.map((lineItem, idx) => {
    const skuDate = skuToSessionDate(lineItem.sku)
    const date =
      lineItem.title.includes('Pre-Paid Arcade Card') ||
      lineItem.title.includes('Booking Protection') ||
      lineItem.title.includes('Gift Card')
        ? lineItem.title
        : `${DateTime.fromJSDate(new Date(skuDate)).toFormat(
            'DDDD'
          )} | ${DateTime.fromJSDate(new Date(skuDate)).toLocaleString(
            DateTime.TIME_SIMPLE
          )}`
    return <BookingRaceItems key={idx} date={date} item={lineItem} />
  })

  const driverBlock = booking?.drivers?.map((driver, idx) => {
    return <BookingDriversList key={idx} driver={driver} index={idx} />
  })

  const getToast: MouseEventHandler<HTMLButtonElement> = () => {
    toast.displayToast({ message: 'Link copied to clipboard' })
    navigator.clipboard.writeText(window.location.href)
  }

  const handleClick = () => {
    navigate(`/bookings/${orderId}/register-driver`)
    if (!order?.tags?.toLowerCase()?.includes('occasion:') && orderId) {
      dispatch({
        type: 'SET_CURRENT_NAVIGATION',
        payload: {
          navigation: {
            currentScreen: SCREEN_NAMES.OCCASION_SCREEN,
            previousScreen: SCREEN_NAMES.BOOKING_DETAILS,
          },
        },
      })
    }
  }

  const bookingInstructionOne = useMemo(
    () =>
      metaobjectConfig?.generalConfig?.bookingScreen?.descriptionOne ||
      BOOKING_INSTRUCTION_1,
    [metaobjectConfig?.generalConfig?.bookingScreen?.descriptionOne]
  )

  const bookingInstructionTwo = useMemo(
    () =>
      metaobjectConfig?.generalConfig?.bookingScreen?.descriptionTwo ||
      BOOKING_INSTRUCTION_2,
    [metaobjectConfig?.generalConfig?.bookingScreen?.descriptionTwo]
  )

  return (
    <>
      <div className="sm:bg-[url('./images/hyperkarting_bg.jpg')] bg-no-repeat bg-cover fixed inset-0 z-[-1]"></div>
      <div className="p-5 sm:p-0 sm:py-10 sm:min-h-full flex items-center justify-center">
        <div className="p-5 flex flex-col bg-white rounded-xl shadow-sm sm:max-w-[550px] sm:mx-auto sm:p-12">
          <div className="text-black text-2xl font-semibold mb-1.5 sm:text-center">
            {order.customer?.first_name
              ? `${order.customer?.first_name} ${
                  order.customer?.last_name || ''
                }'s Booking`
              : BOOKING_NO_NAME}
          </div>
          <div className="mb-4 text-gray-500 text-base font-semibold sm:text-center hidden">
            {bookingDate}
          </div>
          <div className="mb-4 font-normal text-base text-gray-500 sm:text-center">
            {bookingInstructionOne}
          </div>
          <div className="mb-4 font-normal text-base text-gray-500 sm:text-center">
            {bookingInstructionTwo}
          </div>
          <button
            className="mb-4 bg-indigo-700 px-4 py-2 rounded-full flex flex-row self-center cursor-pointer text-white text-sm"
            onClick={getToast}
          >
            <LinkIcon className="h-6 w-6 text-white place-self-center" />
            {BOOKING_COPY_LINK}
          </button>
          <div className="flex justify-center items-center gap-2">
            <div
              className="text-center self-center items-center mb-5 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full cursor-pointer"
              onClick={handleClick}
            >
              {BOOKING_REGISTER_BUTTON_LABEL}
            </div>
          </div>
          <div>{racesBlock}</div>
          <div className="mt-8 mb-2.5">
            <div className="text-black-500 text-xl font-medium border-b	pb-2.5 sm:text-center">
              {BOOKING_REGISTERED_DRIVERS}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between mb-4 pl-2.5">
              <div className="flex items-center justify-between">
                <div className="text-sm	font-normal	text-gray-500 mr-12">
                  {BOOKING_DRIVERS_NO_HEADER}
                </div>
              </div>
              <div className="text-sm	font-normal	text-gray-500">
                {BOOKING_DRIVERS_NAME_HEADER}
              </div>
              <div className="text-sm font-normal text-gray-500">
                {BOOKING_DRIVERS_STATUS_HEADER}
              </div>
            </div>
            {driverBlock}
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingDetails
