import React, {
  useState,
  FocusEventHandler,
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import {
  IDriver,
  IScreen,
  KeyValuePairOfType,
} from '../../interfaces/Registration'
import { defaultDriverData, SCREEN_NAMES } from './Constants'
import DriverForm from './DriverForm'
import DriverListScreen from './DriverListScreen'
import EmailScreen from './EmailScreen'
import AdultDriverCompletion from './AdultDriverCompletion'
import useError from './CustomHookValidation'
import SuccessScreen from './SuccessScreen'
import DriverPreviewScreen from './DriverPreviewScreen'
import JuniorGuardianForm from './JuniorGuardianForm'
import JuniorDriverForm from './JuniorDriverForm'
import SelectYourSessionOptions from './SelectYourSessionOptions'
import SelectYourSessionLists from './SelectYourSessionLists'
import SelectYourSessionSelected from './SelectYourSessionSelected'
import { convertDob } from '../../utils/RegistrationHelpers'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import CheckInThankYou from './checkInThankYou'
import OccasionOption from './occasionOptions'
import BookingDetails from './BookingDetails'

const RegistrationForm = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const { dispatch, drivers, juniors, navigation, isJunior } =
    registrationContext

  const [driver, setDriver] = useState(defaultDriverData as IDriver)
  const [editMode, setEditMode] = useState<boolean>(false)

  const { errors, setErrors, performValidation, removeErrorFromInputField } =
    useError(registrationContext?.driver as IDriver, juniors)

  useEffect(
    () => dispatch({ type: 'SET_REGISTRATION_DRIVER', payload: { driver } }),
    [dispatch, driver]
  )

  const handleOnChangeInput: ChangeEventHandler<HTMLInputElement> = e => {
    switch (e.target.type) {
      case 'checkbox':
        handleDriverFieldChange({
          [e.target.name]: e.target.checked,
        } as unknown as KeyValuePairOfType<IDriver>)
        break
      default:
        let val = e.target.value
        switch (e.target.name) {
          case 'phone_number':
            val = !isNaN(+e.target.value) ? e.target.value : driver.phone_number
            break
          case 'dob':
          case 'guardian_dob':
            val = val.replace(/[/.]/g, '-')
            if (!e.target.defaultValue.endsWith('-')) {
              if (val.length === 2 && !isNaN(val as any)) {
                val = val + '-'
              }
              if (
                val.length === 5 &&
                !isNaN((val.slice(0, 2) + val.slice(3)) as any)
              ) {
                val = val + '-'
              }
            }
            val = val.slice(0, 10)
            break
          default:
            break
        }
        handleDriverFieldChange({
          [e.target.name]: val,
        } as unknown as KeyValuePairOfType<IDriver>)
        break
    }
  }

  const handleChangeDriverFields = useCallback(
    (data: Partial<KeyValuePairOfType<IDriver>>) => {
      handleDriverFieldChange(data)
    },
    []
  )

  const handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement> = e => {
    handleDriverFieldChange({
      [e.target.name]: e.target.value,
    } as unknown as KeyValuePairOfType<IDriver>)
  }

  const handleBlurInput = (
    targetName: string,
    isJunior: boolean,
    index: number
  ) => {
    const { hasError } = isJunior
      ? performValidation(targetName, '', true, index)
      : performValidation(targetName)
    if (!hasError) {
      removeErrorFromInputField(targetName)
    }
  }

  const handleBlurSelect: FocusEventHandler<HTMLSelectElement> = e => {
    const { hasError } = performValidation(e.target.name)
    if (!hasError) {
      removeErrorFromInputField(e.target.name)
    }
  }

  const handleDriverFieldChange: (
    obj: Partial<KeyValuePairOfType<IDriver>>
  ) => void = obj => {
    setDriver(driver => ({ ...driver, ...obj }))
  }

  const openGenericRegEmailScreen = () => {
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: [
          {
            ...defaultDriverData,
          } as IDriver,
        ] as IDriver[],
      },
    })
    dispatch({
      type: 'SET_CURRENT_NAVIGATION',
      payload: {
        navigation: {
          ...navigation,
          currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
        },
      },
    })
  }

  const openSuccessScreen = () => {
    dispatch({
      type: 'SET_CURRENT_NAVIGATION',
      payload: {
        navigation: {
          ...navigation,
          currentScreen: SCREEN_NAMES.SUCCESS_SCREEN,
        },
      },
    })
  }

  const goToRegistrationCompletion = () => {
    dispatch({
      type: 'SET_CURRENT_NAVIGATION',
      payload: {
        navigation: {
          ...navigation,
          currentScreen: SCREEN_NAMES.EMAIL_SELECTION_COMPLETION,
        },
      },
    })
  }

  const backToDriverForm = () => {
    if (drivers && drivers.length !== 0) {
      dispatch({
        type: 'SET_CURRENT_NAVIGATION',
        payload: {
          navigation: {
            ...navigation,
            currentScreen: SCREEN_NAMES.DRIVER_LIST,
          },
        },
      })
    } else {
      dispatch({
        type: 'SET_CURRENT_NAVIGATION',
        payload: {
          navigation: {
            ...navigation,
            currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
          },
        },
      })
    }
  }

  const SCREENS: IScreen = {
    [SCREEN_NAMES.EMAIL_SELECTION_COMPLETION]: {
      key: SCREEN_NAMES.EMAIL_SELECTION_COMPLETION,
      Component: AdultDriverCompletion,
      props: {
        goToNextPage: () => {
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.DRIVER_LIST,
              },
            },
          })
        },
        handleBlurInput,
        performValidation,
        backToPreviousComponent: () => {
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.DRIVER_FORM_ONLY,
              },
            },
          })
        },
        openSuccessScreen,
        errors,
      },
    },
    [SCREEN_NAMES.EMAIL_SELECTION_SCREEN]: {
      key: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
      Component: EmailScreen,
      props: {
        goToNextPage: () => {
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.DRIVER_LIST,
              },
            },
          })
        },
        handleBlurInput,
        performValidation,
        errors,
      },
    },
    [SCREEN_NAMES.DRIVER_LIST]: {
      key: SCREEN_NAMES.DRIVER_LIST,
      Component: DriverListScreen,
      props: {
        goToPreviousPage: () => {
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
              },
            },
          })
        },
        openDriverPreview: (driver: IDriver) => {
          setDriver(driver)
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
              },
            },
          })
        },
        openCreateDriverScreen: (driver: IDriver | undefined | null) => {
          setDriver(driver || (defaultDriverData as IDriver))
          setEditMode(false)
          if (!isJunior) {
            dispatch({
              type: 'SET_CURRENT_NAVIGATION',
              payload: {
                navigation: {
                  ...navigation,
                  currentScreen: SCREEN_NAMES.DRIVER_FORM_ONLY,
                },
              },
            })
          }
        },
      },
    },
    [SCREEN_NAMES.DRIVER_FORM_ONLY]: {
      key: SCREEN_NAMES.DRIVER_FORM_ONLY,
      Component: DriverForm,
      props: {
        driver,
        juniors,
        errors,
        editMode,
        setErrors,
        handleDriverFieldChange,
        handleOnChangeInput,
        handleBlurInput,
        handleOnChangeSelect,
        handleBlurSelect,
        openSuccessScreen: openSuccessScreen,
        goToRegistrationCompletion: goToRegistrationCompletion,
        backToDriverForm: backToDriverForm,
        performValidation,
        handleChangeDriverFields,
        handleGuardianPreviousComponent: () =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
              },
            },
          }),
        handleGuardianNextComponent: () =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
              },
            },
          }),
      },
    },
    [SCREEN_NAMES.DRIVER_PREVIEW_SCREEN]: {
      key: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
      Component: DriverPreviewScreen,
      props: {
        driver,
        openEditDriverScreen: () => {
          setEditMode(true)
          setDriver({
            ...driver,
            ...(convertDob(driver.dob as string) && {
              dob: convertDob(driver.dob) as string,
            }),
            ...(convertDob(driver.guardian_dob as string) && {
              guardian_dob: convertDob(driver.guardian_dob as string) as string,
            }),
          })
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                ...navigation,
                currentScreen: SCREEN_NAMES.DRIVER_FORM_ONLY,
              },
            },
          })
        },
        openSuccessScreen: openSuccessScreen,
      },
    },
    [SCREEN_NAMES.GUARDIAN_DRIVER_FORM_ONLY]: {
      key: SCREEN_NAMES.GUARDIAN_DRIVER_FORM_ONLY,
      Component: JuniorGuardianForm,
      props: {},
    },
    [SCREEN_NAMES.JUNIOR_DRIVER_FORM]: {
      key: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
      Component: JuniorDriverForm,
      props: {},
    },
    [SCREEN_NAMES.SUCCESS_SCREEN]: {
      key: SCREEN_NAMES.SUCCESS_SCREEN,
      Component: SuccessScreen,
      props: {
        driver,
        drivers,
        openGenericRegistrationScreen: openGenericRegEmailScreen,
      },
    },
    [SCREEN_NAMES.SELECT_YOUR_SESSION_OPTIONS]: {
      key: SCREEN_NAMES.SELECT_YOUR_SESSION_OPTIONS,
      Component: SelectYourSessionOptions,
    },
    [SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS]: {
      key: SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS,
      Component: SelectYourSessionLists,
    },
    [SCREEN_NAMES.SELECT_YOUR_SESSION_SELECTED]: {
      key: SCREEN_NAMES.SELECT_YOUR_SESSION_SELECTED,
      Component: SelectYourSessionSelected,
    },
    [SCREEN_NAMES.SELECT_YOUR_SESSION_THANK_YOU]: {
      key: SCREEN_NAMES.SELECT_YOUR_SESSION_THANK_YOU,
      Component: CheckInThankYou,
    },
    [SCREEN_NAMES.OCCASION_SCREEN]: {
      key: SCREEN_NAMES.OCCASION_SCREEN,
      Component: OccasionOption,
    },
    [SCREEN_NAMES.BOOKING_DETAILS]: {
      key: SCREEN_NAMES.BOOKING_DETAILS,
      Component: BookingDetails,
    },
  }
  const { Component, props } = SCREENS[navigation.currentScreen]

  return (
    <>
      <Component {...props} />
    </>
  )
}

export default RegistrationForm
