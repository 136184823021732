import { useContext } from 'react'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import {
  SCREEN_NAMES,
  SELECT_YOUR_SESSION_SUMMARY,
  SELECT_YOUR_SESSION_SUB_HEADER_THREE,
} from './Constants'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import Header from './Header'
import { getDatetimeFromSku } from '../../utils/getDateFromSku'
import { postRequest } from '../../api'

interface IPayload {
  driverId: string
  orderId: string
  sku: string
}

const SelectYourSessionSelected = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, selectedSession, driver, orderId } = registrationContext

  const handleAddToSession = async () => {
    if (!driver || !selectedSession) {
      console.error('Driver or selectedSession information is missing')
      return
    }

    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true,
      },
    })

    try {
      const selfCheckInPayload: IPayload[] = selectedSession.map(session => ({
        driverId: driver.firebase_id as string,
        orderId: orderId,
        sku: session.sku,
      }))

      const { data } = await postRequest('selfCheckin', {
        selfCheckIn: selfCheckInPayload,
      })

      if (data) {
        dispatch({
          type: 'SET_LOADING',
          payload: {
            loading: false,
          },
        })

        dispatch({
          type: 'SET_CURRENT_NAVIGATION',
          payload: {
            navigation: {
              currentScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_THANK_YOU,
              previousScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_SELECTED,
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: false,
        },
      })
      console.error('Error in handleAddToSession:', error)
    }
  }

  return (
    <>
      <button
        className="text-[#5B36DF] font-semibold absolute top-4 left-5 flex items-center"
        onClick={() =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                currentScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS,
                previousScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_SELECTED,
              },
            },
          })
        }
      >
        <ChevronLeftIcon className="w-[22px] h-[22px]" /> Go Back
      </button>

      <Header
        title={SELECT_YOUR_SESSION_SUMMARY}
        description={SELECT_YOUR_SESSION_SUB_HEADER_THREE}
      />

      {selectedSession &&
        selectedSession.length > 0 &&
        selectedSession.map(session => (
          <div
            key={session.id}
            className="mb-2.5 px-5 py-4 w-full border-[2px] border-solid rounded-lg cursor-pointer font-bold"
          >
            {session.title.includes('|')
              ? session.title.split('|')[0]
              : session.title}{' '}
            | {getDatetimeFromSku(session.sku)?.toFormat('hh:mma')}
          </div>
        ))}

      {selectedSession && (
        <button
          onClick={handleAddToSession}
          className={`
            w-full font-semibold py-[15px] px-[25px] capitalize text-[16px] rounded-full
            ${
              selectedSession
                ? 'bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] text-white cursor-pointer'
                : 'bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] opacity-50 cursor-not-allowed'
            }
            `}
        >
          Confirm
        </button>
      )}
    </>
  )
}

export default SelectYourSessionSelected
