import React, { useContext, useMemo } from 'react'
import { IDriver } from '../../interfaces/Registration'
import QrCode from 'qrcode.react'

import {
  ADULT_FLOW_REGISTRATION_COMPLETE,
  BOOKING_REGISTRATION_COMPLETED_DESC,
  BOOKING_REGISTRATION_COMPLETED_SUBTEXT,
  BOOKING_REGISTRATION_COMPLETED_TITLE,
  GENERIC_REGISTRATION_COMPLETED_STAFF_SUBTEXT,
  GENERIC_REGISTRATION_COMPLETED_TITLE,
  SCREEN_NAMES,
} from './Constants'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'

type Props = {
  driver: IDriver
  drivers: IDriver[]
  openGenericRegistrationScreen: Function
}

const SuccessScreen = (props: Props) => {
  const { driver, drivers } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const {
    isJunior,
    isGenericDriverRegistration,
    itemRegistrationLink,
    dispatch,
    metaobjectConfig,
    booking,
    navigation,
  } = registrationContext

  const { adultFlowConfig } = metaobjectConfig

  const RegistrationCompleteTitleBlock = (
    <div className="text-black text-center text-[20px] md:text-[30px] font-[700]">
      {isGenericDriverRegistration
        ? GENERIC_REGISTRATION_COMPLETED_TITLE
        : BOOKING_REGISTRATION_COMPLETED_TITLE}
    </div>
  )

  const adultFlowRegistrationCompletedHeader = useMemo(
    () =>
      (adultFlowConfig &&
        adultFlowConfig?.registrationComplitionScreen.title) ||
      GENERIC_REGISTRATION_COMPLETED_TITLE,
    [adultFlowConfig]
  )
  const adultFlowRegistrationCompletedDescription = useMemo(
    () =>
      (adultFlowConfig &&
        adultFlowConfig?.registrationComplitionScreen.description) ||
      ADULT_FLOW_REGISTRATION_COMPLETE,
    [adultFlowConfig]
  )

  const filterJuniorDrivers: IDriver[] = useMemo(
    () =>
      drivers.filter(
        (junior: IDriver) => junior.first_name && junior.last_name && junior.dob
      ),
    [drivers]
  ) as IDriver[]

  const getDriversLength: number = useMemo(() => {
    if (booking && booking?.drivers) {
      return booking?.drivers?.length
    }
    return 0
  }, [booking])

  return (
    <>
      {isJunior ? (
        <>
          <div className="text-black text-center text-[20px] md:text-[30px] font-[700]">
            {GENERIC_REGISTRATION_COMPLETED_TITLE}
          </div>
          <p className="text-[#777] text-center text-[14px] font-[500]">
            thank you for your registration
          </p>
          <p className="text-center text-[#555758] font-[600] text-[20px] my-[20px] capitalize">
            {isJunior && drivers && drivers.length
              ? drivers[0].guardian_first_name
              : registrationContext.driver &&
                registrationContext.driver.guardian_first_name}{' '}
            {isJunior && drivers && drivers.length
              ? drivers[0].guardian_last_name
              : registrationContext.driver &&
                registrationContext.driver &&
                registrationContext.driver.guardian_last_name}
          </p>
          <div className="bg-[#F3F4F7] my-[10px] overflow-hidden rounded-[15px]">
            <div className="text-center text-[#555758] text-[20px] font-[600] w-full bg-[#EBECEF] py-[15px]">
              Number Registered: {filterJuniorDrivers.length}
            </div>
            <div className="mb-[30px]">
              {drivers?.map((junior, idx) => (
                <div
                  key={idx}
                  className="font-[600] text-[14px] sm:text-[16px] text-[#777] text-center"
                >
                  {junior.first_name} {junior.last_name}
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center gap-3 my-[20px]">
              <div className="text-center text-[#555758] text-[20px] font-[600]">
                Total Registered:
              </div>
              <div className="bg-[#9D62FE] rounded-full flex justify-center items-center w-[60px] h-[60px] opacity-[0.5]">
                <span className="text-[26px] text-white font-[700]">
                  {getDriversLength}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {itemRegistrationLink &&
          !itemRegistrationLink.includes('undefined') ? (
            <>
              <h2 className="text-center text-[20px] md:text-[30px] font-[700]">
                {adultFlowRegistrationCompletedHeader}
              </h2>
              <p className="text-center text-[#777] text-[14px] md:px-[60px]">
                {adultFlowRegistrationCompletedDescription}
              </p>
            </>
          ) : (
            RegistrationCompleteTitleBlock
          )}
          <div className="sm:mb-1 mb-1.5 text-xl font-normal text-base text-gray-500 text-center">
            {itemRegistrationLink &&
              !itemRegistrationLink.includes('undefined') && (
                <div className="flex justify-center items-center my-[30px]">
                  <QrCode value={itemRegistrationLink} size={248} />
                </div>
              )}
            {itemRegistrationLink &&
            !itemRegistrationLink.includes('undefined') ? (
              <div className="text-center text-[#777] mb-[30px]">
                <p>
                  {registrationContext?.driver?.first_name}{' '}
                  {registrationContext?.driver?.last_name}
                </p>
                {registrationContext?.driver?.address && (
                  <p>{registrationContext?.driver?.address}</p>
                )}

                {registrationContext?.driver?.phone_number && (
                  <p>({registrationContext?.driver?.phone_number})</p>
                )}
              </div>
            ) : isGenericDriverRegistration ? (
              `${driver.first_name} ${driver.last_name}`
            ) : (
              BOOKING_REGISTRATION_COMPLETED_DESC
            )}

            {!isGenericDriverRegistration &&
              navigation?.previousScreen !== 'SELECT_YOUR_SESSION_OPTIONS' && (
                <button
                  onClick={() =>
                    dispatch({
                      type: 'SET_CURRENT_NAVIGATION',
                      payload: {
                        navigation: {
                          previousScreen: SCREEN_NAMES.SUCCESS_SCREEN,
                          currentScreen:
                            SCREEN_NAMES.SELECT_YOUR_SESSION_OPTIONS,
                        },
                      },
                    })
                  }
                  className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] capitalize text-[16px] rounded-full text-white sm:min-w-[335px] min-w-[100%]"
                >
                  Select your session
                </button>
              )}
          </div>
        </>
      )}
      {!itemRegistrationLink &&
        !itemRegistrationLink.includes('undefined') &&
        !isJunior && (
          <>
            <div className="text-base font-normal sm:text-center mb-[20px]">
              {isGenericDriverRegistration
                ? GENERIC_REGISTRATION_COMPLETED_STAFF_SUBTEXT
                : BOOKING_REGISTRATION_COMPLETED_SUBTEXT}
            </div>
          </>
        )}
    </>
  )
}

export default SuccessScreen
