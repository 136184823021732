import React, {
  useState,
  useContext,
  useRef,
  FocusEventHandler,
  useEffect,
  ChangeEventHandler,
  useMemo,
} from 'react'
import { IDriver, KeyValuePairOfType } from '../../interfaces/Registration'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import Header from './Header'
import {
  REGISTRATION_DESC,
  UPDATE_EXISITING_DRIVER_TITLE,
  UPDATE_NONEXISITING_DRIVER_TITLE,
  GUARDIAN_REGISTRATION_TITLE,
  GUARDIAN_REGISTRATION_DESC,
  CREATE_HYPER_KARTING_ACCOUNT,
} from './Constants'
// import JuniorDriverForm from './JuniorDriverForm'
import AdultDriverFormOnly from './AdultDriverFormOnly'
import { postRequest } from '../../api'
import { useToast } from '../../context/ToastContext'
import JuniorGuardianForm from './JuniorGuardianForm'
import { sentryMessage } from '../../helper/sentry'

type Props = {
  driver: IDriver
  juniors: IDriver[]
  errors: any
  editMode: boolean
  goToNextPage: Function
  setJuniors: Function
  setErrors: Function
  handleBlurInput: Function
  handleDriverFieldChange: Function
  handleOnChangeInput: ChangeEventHandler<HTMLInputElement>
  handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement>
  handleBlurSelect: FocusEventHandler<HTMLSelectElement>
  openSuccessScreen: Function
  goToRegistrationCompletion: Function
  backToDriverForm: Function
  performValidation: Function
  handleChangeDriverFields: (data: Partial<KeyValuePairOfType<IDriver>>) => void
}

const DriverForm = (props: Props) => {
  const {
    editMode,
    errors,
    openSuccessScreen,
    performValidation,
    driver,
    goToRegistrationCompletion,
    backToDriverForm,
    handleChangeDriverFields,
  } = props
  const addressRef = useRef<HTMLInputElement | null>(null)
  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const lastNameRef = useRef<HTMLInputElement | null>(null)
  const phoneRef = useRef<HTMLInputElement | null>(null)
  const dobRef = useRef<HTMLInputElement | null>(null)
  const signatureRef = useRef({} as SignaturePad | null)
  const [isSubmitDisable, setIsSubmitDisable] = useState<boolean>(true)
  const toast = useToast()
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const {
    orderId,
    email,
    isJunior,
    isGenericDriverRegistration,
    setLoading,
    getDrivers,
    dispatch,
    navigation,
    metaobjectConfig,
  } = registrationContext

  const { adultFlowConfig } = metaobjectConfig

  const createNewDriverTitle = useMemo(
    () =>
      (adultFlowConfig && adultFlowConfig?.createProfileScreen?.title) ||
      UPDATE_NONEXISITING_DRIVER_TITLE,
    [adultFlowConfig]
  )

  const createNewDriverDesc = useMemo(
    () =>
      (adultFlowConfig && adultFlowConfig?.createProfileScreen?.description) ||
      CREATE_HYPER_KARTING_ACCOUNT,
    [adultFlowConfig]
  )

  useEffect(() => {
    setIsSubmitDisable(!!Object.values(errors).filter(e => e).length)
  }, [errors])

  useEffect(() => {
    if (signatureRef?.current?.fromDataURL && driver.signature_url) {
      signatureRef?.current.fromDataURL(driver.signature_url)
    }
  }, [signatureRef, driver.signature_url])

  const handleSubmit = async (
    driverList: IDriver[],
    isJunior: boolean = false,
    isJuniorIncompleteFocus: boolean = false
  ) => {
    const { hasError, validationErrors } = performValidation(
      'all',
      '',
      isJunior
    )
    if (hasError) {
      toast.displayToast({
        message: 'Please fill required fields',
      })
      if (!isJuniorIncompleteFocus) {
        if (isJunior && validationErrors[0].includes('Guardian age')) {
          dobRef?.current?.focus()
        } else if (validationErrors[0].includes('first name')) {
          firstNameRef?.current?.focus()
        } else if (validationErrors[0].includes('last name')) {
          lastNameRef?.current?.focus()
        } else if (validationErrors[0].includes('address')) {
          addressRef?.current?.focus()
        } else if (validationErrors[0].includes('phone number')) {
          phoneRef?.current?.focus()
        } else if (
          validationErrors[0].includes('DOB') ||
          validationErrors[0].includes('Enter valid date') ||
          validationErrors[0].includes('Drivers under 16')
        ) {
          dobRef?.current?.focus()
        }
      }
      return
    }

    setLoading(true)
    const requestURL = editMode ? 'updateDrivers' : 'createDrivers'

    sentryMessage('DriverForm: handleSubmit', 'log', {
      driverList: driverList,
      requestURL: requestURL,
      editMode: editMode,
    })

    const { data } = await postRequest(requestURL, {
      data: {
        drivers: driverList,
      },
    })
    let newDriverResponse = data.result.drivers

    if (!isGenericDriverRegistration) {
      const res = await postRequest('addDriverToBooking', {
        data: {
          drivers: newDriverResponse,
          orderId: orderId,
        },
      })
      console.log('Recieved res addDriverToBooking: ', res)
      await getDrivers(email)
    }
    setLoading(false)
    openSuccessScreen()
  }

  useEffect(() => {
    // send reducer action
    if (window.location.pathname.includes('/bookings/') && orderId) {
      const itemRegistrationLink = `${window.location.origin}/bookings/${orderId}`
      dispatch({
        type: 'SET_REGISTRATION_QR_LINK',
        payload: {
          itemRegistrationLink: itemRegistrationLink,
        },
      })
    }
  }, [dispatch, orderId])

  console.log('=== driver at DriverForm ===', driver)

  return (
    <>
      <Header
        title={
          isJunior
            ? GUARDIAN_REGISTRATION_TITLE
            : editMode
            ? UPDATE_EXISITING_DRIVER_TITLE
            : createNewDriverTitle
        }
        description={
          isJunior
            ? GUARDIAN_REGISTRATION_DESC
            : editMode
            ? 'Please add your child details that gonna Race'
            : navigation.currentScreen === 'DRIVER_FORM_ONLY'
            ? createNewDriverDesc
            : REGISTRATION_DESC
        }
      />
      {isJunior ? (
        <JuniorGuardianForm />
      ) : (
        <AdultDriverFormOnly
          {...props}
          addressRef={addressRef}
          firstNameRef={firstNameRef}
          lastNameRef={lastNameRef}
          phoneRef={phoneRef}
          dobRef={dobRef}
          signRef={signatureRef}
          isSubmitDisable={isSubmitDisable}
          goToNextPage={goToRegistrationCompletion}
          backToDriverForm={backToDriverForm}
          handleSubmit={handleSubmit}
          handleChangeDriverFields={handleChangeDriverFields}
        />
      )}
    </>
  )
}

export default DriverForm
