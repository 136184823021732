import React, { useContext, useMemo } from 'react'
import RegistrationForm from './RegistrationFormWrapper'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'

import JuniorTermsAndCondition from './JuniorTermAndConditions'
import { SCREEN_NAMES } from './Constants'
import { IDriver } from '../../interfaces/Registration'
import { DateTime } from 'luxon'

type Props = {}

const Registration = (props: Props) => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const { isJunior, navigation, driver, juniors, drivers, hasError } =
    registrationContext

  const filterJuniorDrivers: IDriver[] = useMemo(
    () =>
      [...juniors, ...drivers].filter(
        (driver: IDriver) =>
          driver.first_name &&
          driver.last_name &&
          driver.dob &&
          (DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').isValid ||
            DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').toMillis() >
              DateTime.now().toMillis() ||
            DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').toMillis() <
              DateTime.now().minus({ years: 16, days: 1 }).toMillis()) &&
          driver.gender
      ),
    [drivers, juniors]
  ) as IDriver[]

  return (
    <>
      <div
        className={`bg-black flex justify-center ${
          isJunior ? 'flex-col' : ''
        } items-center p-3 min-h-[calc(100vh_-_75px)] sm:bg-[url('./images/hyperkarting_bg.jpg')] bg-no-repeat bg-cover sm:p-0`}
      >
        <div
          className={`w-full ${
            isJunior ? '' : 'sm:p-0 sm:py-10'
          } sm:min-h-full items-center justify-center`}
        >
          <div
            className={`flex flex-col bg-white rounded-[25px] shadow-sm w-full sm:w-[600px] sm:max-w-[650px] sm:mx-auto sm:my-10 p-[40px] sm:py-12 sm:px-[30px] relative`}
          >
            <RegistrationForm />
          </div>
        </div>
        {isJunior &&
          navigation.currentScreen === SCREEN_NAMES.JUNIOR_DRIVER_FORM && (
            <div className="sm:min-h-full items-center justify-center">
              <div className="flex flex-col bg-white rounded-[25px] shadow-sm w-full sm:w-[600px] sm:max-w-[650px] sm:mx-auto sm:mb-10 p-[40px]	sm:pb-12 sm:pt-[0px] sm:px-[30px]">
                <div className="mt-[40px]">
                  <h2 className="font-[700] text-[20px] text-[#000] text-center capitalize">
                    Registration Summary
                  </h2>
                  <div className="my-[30px]">
                    {driver &&
                      driver.guardian_first_name &&
                      driver.guardian_last_name && (
                        <p className="text-[#777] text-[12px] font-[700] text-center capitalize">
                          {driver.guardian_first_name}{' '}
                          {driver.guardian_last_name}
                        </p>
                      )}
                    {driver && driver.address && (
                      <p className="text-[#777] text-[12px] font-[700] text-center">
                        {driver.address}
                      </p>
                    )}
                    {driver && driver.phone_number && (
                      <p className="text-[#777] text-[12px] font-[700] text-center">
                        ({driver.phone_number})
                      </p>
                    )}
                    <div className="bg-[#9D62FE] rounded-full flex justify-center items-center w-[77px] h-[77px] opacity-[0.5] mx-auto">
                      <span className="text-[40px] text-white font-[700]">
                        {!hasError ? `${filterJuniorDrivers.length}` : '0'}
                      </span>
                    </div>
                    <p className="text-[#777] text-[14px] font-[500] text-center capitalize">
                      total registered
                    </p>
                  </div>
                </div>
                <JuniorTermsAndCondition />
              </div>
            </div>
          )}
      </div>
    </>
  )
}

export default Registration
