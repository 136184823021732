import { DateTime } from 'luxon'
export const getDatetimeFromSku = (sku: string) => {
  let raceDate = sku?.split('.')?.[0]
  if (raceDate) {
    if (raceDate && !isNaN(raceDate as any)) {
      const dt = DateTime.fromFormat(raceDate, 'yyMMddHHmm', {
        zone: 'Australia/Sydney',
      })

      return dt
    }
  }
  return null
}
