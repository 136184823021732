import { useContext, useState } from 'react'
import {
  SCREEN_NAMES,
  OCCASION_HEADER,
  OCCASION_DESCRIPTION,
} from './Constants'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { postRequest } from '../../api'

const OccasionOption = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, orderId } = registrationContext
  let navigate = useNavigate()
  const [selectedOccasion, setSelectedOccasion] = useState<
    'Personal' | 'Group' | 'Corporate' | 'Party' | 'Other' | null
  >(null)

  const handleAddOrderTag = async () => {
    if (!selectedOccasion && !orderId) {
      console.error('==== Occasion or orderid is not available ====')
      return
    }

    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true,
      },
    })

    try {
      const { data } = await postRequest('bookingOccasion', {
        orderId: orderId,
        attribution: selectedOccasion?.toLowerCase(),
      })

      if (data) {
        dispatch({
          type: 'SET_LOADING',
          payload: {
            loading: false,
          },
        })

        navigate(`/bookings/${orderId}/register-driver`)
        dispatch({
          type: 'SET_CURRENT_NAVIGATION',
          payload: {
            navigation: {
              currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
              previousScreen: SCREEN_NAMES.OCCASION_SCREEN,
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: false,
        },
      })
      console.error('Error in handleAddToSession:', error)
    }
  }

  return (
    <>
      <Header title={OCCASION_HEADER} description={OCCASION_DESCRIPTION} />
      <div className="flex flex-col justify-start gap-4">
        <div
          className="flex justify-start items-center gap-4 hover:cursor-pointer"
          onClick={() => setSelectedOccasion('Personal')}
        >
          <span className="h-5 w-5 border-[2px] border-[#9d62fecc] rounded-lg flex justify-center items-center">
            {selectedOccasion && selectedOccasion === 'Personal' && (
              <span className="h-3.5 w-3.5 bg-[#9d62fecc] rounded-lg block"></span>
            )}
          </span>
          <span>Personal</span>
        </div>
        <div
          className="flex justify-start items-center gap-4 hover:cursor-pointer"
          onClick={() => setSelectedOccasion('Group')}
        >
          <span className="h-5 w-5 border-[2px] border-[#9d62fecc] rounded-lg flex justify-center items-center">
            {selectedOccasion && selectedOccasion === 'Group' && (
              <span className="h-3.5 w-3.5 bg-[#9d62fecc] rounded-lg block"></span>
            )}
          </span>
          <span>Group</span>
        </div>
        <div
          className="flex justify-start items-center gap-4 hover:cursor-pointer"
          onClick={() => setSelectedOccasion('Corporate')}
        >
          <span className="h-5 w-5 border-[2px] border-[#9d62fecc] rounded-lg flex justify-center items-center">
            {selectedOccasion && selectedOccasion === 'Corporate' && (
              <span className="h-3.5 w-3.5 bg-[#9d62fecc] rounded-lg block"></span>
            )}
          </span>
          <span>Corporate</span>
        </div>
        <div
          className="flex justify-start items-center gap-4 hover:cursor-pointer"
          onClick={() => setSelectedOccasion('Party')}
        >
          <span className="h-5 w-5 border-[2px] border-[#9d62fecc] rounded-lg flex justify-center items-center">
            {selectedOccasion && selectedOccasion === 'Party' && (
              <span className="h-3.5 w-3.5 bg-[#9d62fecc] rounded-lg block"></span>
            )}
          </span>
          <span>Party</span>
        </div>
        <div
          className="flex justify-start items-center gap-4 hover:cursor-pointer"
          onClick={() => setSelectedOccasion('Other')}
        >
          <span className="h-5 w-5 border-[2px] border-[#9d62fecc] rounded-lg flex justify-center items-center">
            {selectedOccasion && selectedOccasion === 'Other' && (
              <span className="h-3.5 w-3.5 bg-[#9d62fecc] rounded-lg block"></span>
            )}
          </span>
          <span>Other</span>
        </div>
      </div>

      <button
        onClick={handleAddOrderTag}
        disabled={!selectedOccasion}
        className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] mt-8 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-white sm:min-w-[335px] min-w-[100%] ${
          !selectedOccasion ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
        }`}
      >
        Continue
      </button>
    </>
  )
}

export default OccasionOption
