import React, {
  useContext,
  FC,
  FocusEventHandler,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  ADULT_DEFAULT_GENDER,
  ADULT_GENDER_FEMALE,
  ADULT_GENDER_LABEL,
  ADULT_GENDER_MALE,
  ADULT_GENDER_NOTDISCLOSE,
  JUNIOR_FORM_GUARDIAN_ADDRESS_LABEL,
  JUNIOR_FORM_GUARDIAN_DOB_LABEL,
  JUNIOR_FORM_GUARDIAN_FIRST_NAME_LABEL,
  JUNIOR_FORM_GUARDIAN_LAST_NAME_LABEL,
  JUNIOR_FORM_GUARDIAN_PHONE_NO_LABEL,
  SCREEN_NAMES,
} from './Constants'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'

import PhoneInput from 'react-phone-number-input'
import { IDriver } from '../../interfaces/Registration'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import useError from './CustomHookValidation'
import Capitalize from '../../utils/Capitalize'
import CustomDatepicker from './CustomDatepicker'

type TProps = {}

const JuniorGuardianForm: FC<TProps> = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const {
    dispatch,
    navigation,
    juniors,
    selectedDOBError,
    selectedDOB,
    addressError,
  } = registrationContext
  const [isAllInputsFilled, setIsAllInputsFilled] = useState<boolean>(false)
  const [addressInteracted, setAddressInteracted] = useState<boolean>(false)

  const { errors, performValidation, removeErrorFromInputField } = useError(
    registrationContext?.driver as IDriver,
    juniors
  )
  const handleBlurInput = (targetName: string, isJunior: boolean) => {
    const { hasError } = isJunior
      ? performValidation(targetName, 'all', true)
      : performValidation(targetName)
    if (!hasError) {
      removeErrorFromInputField(targetName)
    }
  }
  const blurInputHandler: FocusEventHandler<HTMLInputElement> = e => {
    handleBlurInput(e.target.name, true)
  }

  const blurInputSelector: FocusEventHandler<HTMLSelectElement> = e => {
    handleBlurInput(e.target.name, true)
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: 'au' },
    },
    debounce: 700,
  })

  useEffect(() => {
    if (errors && errors.address && value !== '') {
      removeErrorFromInputField('address')
    }
  }, [errors, removeErrorFromInputField, value])

  const handleSelect = (value: any) => async () => {
    const { description, place_id: placeId } = value

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false)
    clearSuggestions()

    const results = (await getDetails({
      placeId,
    })) as google.maps.places.PlaceResult

    const country = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes('country')
    )

    const zip_code = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes('postal_code')
    )

    const streetNumber = results.address_components?.find((component: any) =>
      component.types.includes('street_number')
    )

    const route = results.address_components?.find((component: any) =>
      component.types.includes('route')
    )

    if (
      streetNumber === undefined ||
      route === undefined ||
      zip_code === undefined
    ) {
      dispatch({
        type: 'SET_ADDRESS_ERROR',
        payload: {
          addressError:
            'The address you entered is not valid. Please enter a valid address.',
        },
      })
    } else {
      dispatch({
        type: 'SET_ADDRESS_ERROR',
        payload: {
          addressError: '',
        },
      })
    }

    const city_id = 2284
    const city = 'Sydney'

    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: {
          ...registrationContext.driver,
          address: description,
          country: country?.short_name,
          place_id: placeId,
          zip_code: zip_code?.long_name,
          city_id: city_id,
          city: city,
        } as IDriver,
      },
    })
  }

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li
          className="border-b py-2.5 cursor-pointer last:border-0"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  useEffect(() => {
    if (registrationContext.driver) {
      const allInputsFilled =
        registrationContext.driver.guardian_first_name !== '' &&
        registrationContext.driver.guardian_last_name !== '' &&
        registrationContext.driver.gender !== '' &&
        registrationContext.driver.guardian_dob !== '' &&
        registrationContext.driver.phone_number !== '' &&
        registrationContext.driver.address !== '' &&
        registrationContext.driver.zip_code !== '' &&
        registrationContext.driver.city !== '' &&
        selectedDOB.day !== '' &&
        selectedDOB.month !== '' &&
        selectedDOB.year !== ''

      const noErrors =
        !errors.guardian_first_name &&
        !errors.guardian_last_name &&
        !errors.gender &&
        !selectedDOBError &&
        !errors.phone_number &&
        !errors.address

      if (allInputsFilled && noErrors) {
        setIsAllInputsFilled(true)
      } else {
        setIsAllInputsFilled(false)
      }
    }
  }, [
    errors.address,
    errors.gender,
    errors.guardian_dob,
    errors.guardian_first_name,
    errors.guardian_last_name,
    errors.phone_number,
    registrationContext.driver,
    selectedDOB.day,
    selectedDOB.month,
    selectedDOB.year,
    selectedDOBError,
  ])

  useEffect(() => {
    if (
      value === '' &&
      registrationContext &&
      registrationContext.driver &&
      registrationContext.driver.address !== ''
    ) {
      dispatch({
        type: 'SET_REGISTRATION_DRIVER',
        payload: {
          driver: {
            ...registrationContext.driver,
            address: '',
            country: '',
            place_id: '',
            zip_code: '',
            city_id: 0,
            city: '',
          } as IDriver,
        },
      })
    }
  }, [dispatch, registrationContext, registrationContext.driver, value])

  const capitalizeFirstName = useMemo(
    () =>
      registrationContext.driver &&
      Capitalize(registrationContext.driver?.guardian_first_name as string),
    [registrationContext.driver]
  )

  const capitalizeLastName = useMemo(
    () =>
      registrationContext.driver &&
      Capitalize(registrationContext.driver?.guardian_last_name as string),
    [registrationContext.driver]
  )

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | string
      | undefined,
    field?: string
  ) => {
    if (typeof e === 'string' || typeof e === 'undefined') {
      dispatch({
        type: 'SET_REGISTRATION_DRIVER',
        payload: {
          driver: {
            ...registrationContext.driver,
            [field!]: e ?? '',
          } as IDriver,
        },
      })
    } else {
      const { name, value } = e.target
      dispatch({
        type: 'SET_REGISTRATION_DRIVER',
        payload: {
          driver: {
            ...registrationContext.driver,
            [name]: value,
          } as IDriver,
        },
      })
    }
  }

  console.log('addressError', addressError)

  return (
    <div>
      <div className="mb-[30px]">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
              {JUNIOR_FORM_GUARDIAN_FIRST_NAME_LABEL}
            </label>
            <input
              name="guardian_first_name"
              onBlur={blurInputHandler}
              onChange={e => handleInputChange(e)}
              value={capitalizeFirstName ?? ''}
              type="text"
              placeholder="First Name"
              className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base ${
                errors.guardian_first_name && 'border-red-500'
              }`}
            />
            <p
              className={`text-red-500 text-sm ${
                errors.guardian_first_name ? 'visible' : 'invisible'
              }`}
            >
              {errors.guardian_first_name}
            </p>
          </div>
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base	leading-5 mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
              {JUNIOR_FORM_GUARDIAN_LAST_NAME_LABEL}
            </label>
            <input
              name="guardian_last_name"
              onBlur={blurInputHandler}
              onChange={e => handleInputChange(e)}
              value={capitalizeLastName ?? ''}
              type="text"
              placeholder="Last Name"
              className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base ${
                errors.guardian_last_name && 'border-red-500'
              }`}
            />
            <p
              className={`text-red-500 text-sm ${
                errors.guardian_last_name ? 'visible' : 'invisible'
              }`}
            >
              {errors.guardian_last_name}
            </p>
          </div>
          <div className="md:flex md:items-center md:gap-[10px]">
            <div className="flex flex-col mt-4">
              <label className="text-base mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
                {JUNIOR_FORM_GUARDIAN_DOB_LABEL}
              </label>
              <CustomDatepicker datepickerUsedFor="Adult Junior Form" />
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-base	leading-5 mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
                {ADULT_GENDER_LABEL}
              </label>
              <select
                name="gender"
                onBlur={blurInputSelector}
                onChange={e => handleInputChange(e)}
                value={registrationContext.driver?.gender ?? 'select'}
                className={`outline-0 px-4	py-3 text-base border rounded-xl w-full appearance-none ${
                  errors.gender && 'border-red-500'
                }`}
              >
                <option value="select" disabled>
                  {ADULT_DEFAULT_GENDER}
                </option>
                <option value="male">{ADULT_GENDER_MALE}</option>
                <option value="female">{ADULT_GENDER_FEMALE}</option>
                <option value="non-disclose">{ADULT_GENDER_NOTDISCLOSE}</option>
              </select>
              <p
                className={`text-red-500 text-sm ${
                  errors.gender ? 'visible' : 'invisible'
                }`}
              >
                {errors.gender}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-5 relative ">
          <label className="text-base	leading-5 mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
            {JUNIOR_FORM_GUARDIAN_PHONE_NO_LABEL}
          </label>
          <PhoneInput
            type="text"
            name="phone_number"
            onBlur={blurInputHandler}
            onChange={(e: any) => handleInputChange(e, 'phone_number')}
            value={registrationContext.driver?.phone_number ?? ''}
            placeholder="0412 345 678"
            className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${
              errors.phone_number && 'border-red-500'
            }`}
            defaultCountry="AU"
          />
          <p
            className={`text-red-500 text-sm ${
              errors.phone_number ? 'visible' : 'invisible'
            }`}
          >
            {errors.phone_number}
          </p>
        </div>
        <div className="flex flex-col mt-4 relative">
          <label className="text-base	leading-5 mb-[10px] text-[#2F1160] font-[500] md:font-[700]">
            {JUNIOR_FORM_GUARDIAN_ADDRESS_LABEL}
          </label>
          <input
            autoComplete="off"
            type="text"
            onChange={e => setValue(e.target.value)}
            value={value}
            disabled={!ready}
            name="address"
            placeholder="Address"
            className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${
              errors.address && !value && 'border-red-500'
            }`}
            onBlur={e => {
              blurInputHandler(e)
              setAddressInteracted(true)
            }}
          />
          {status === 'OK' && (
            <ul className="absolute bg-white border rounded-xl py-0.5 p-3	max-h-[350px] overflow-auto	z-[999] w-full shadow-lg top-[85px]">
              {renderSuggestions()}
            </ul>
          )}
          <p
            className={`text-red-500 text-sm ${
              (errors.address && !value) ||
              (registrationContext.driver &&
                (!registrationContext.driver.zip_code ||
                  !registrationContext.driver.city))
                ? 'visible'
                : addressError
                ? 'visible'
                : 'invisible'
            }`}
          >
            {errors.address
              ? errors.address
              : addressInteracted &&
                registrationContext.driver &&
                !registrationContext.driver.zip_code &&
                !registrationContext.driver.city
              ? 'Please select an address from the suggested addresses dropdown'
              : addressError
              ? addressError
              : ''}
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center gap-[10px]">
        <button
          className={`w-fit self-center items-center border-[2px] border-[#9D62FE] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-[#9D62FE] w-full`}
          onClick={() =>
            dispatch({
              type: 'SET_CURRENT_NAVIGATION',
              payload: {
                navigation: {
                  ...navigation,
                  currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
                },
              },
            })
          }
        >
          Back
        </button>
        <button
          className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white ${
            !isAllInputsFilled || addressError
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          }`}
          disabled={!isAllInputsFilled}
          onClick={() => {
            if (addressError) return

            dispatch({
              type: 'SET_CURRENT_NAVIGATION',
              payload: {
                navigation: {
                  ...navigation,
                  currentScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
                },
              },
            })
          }}
          onMouseOver={() => {
            if (!(selectedDOB.day || selectedDOB.month || selectedDOB.year)) {
              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError:
                    'Please make sure you selected your date of birth.',
                },
              })
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default JuniorGuardianForm
