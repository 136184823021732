import {
  SELECT_YOUR_SESSION_THANK_YOU,
  SELECT_YOUR_SESSION_THANK_YOU_SUB_HEADER,
} from './Constants'
import Header from './Header'

const CheckInThankYou = () => {
  return (
    <Header
      title={SELECT_YOUR_SESSION_THANK_YOU}
      description={SELECT_YOUR_SESSION_THANK_YOU_SUB_HEADER}
    />
  )
}

export default CheckInThankYou
